<template>
  <div class="main-container">
    <div class="check-userinfo">
      <div class="check-color">
        <van-field v-model="userInfo.name" label="姓名" readonly />
      </div>
      <div class="check-color">
        <van-field v-model="userInfo.idNo" label="证件号码" readonly />
      </div>
      <div>
        <van-field v-model="userInfo.gender" label="性别" />
        <van-field v-model="userInfo.nation" label="民族" />
        <van-field v-model="userInfo.birthDay" label="出生日期" />
        <van-field v-model="userInfo.address" type="textarea" label="户籍地址" />
      </div>
    </div>
    <div class="check-userinfo mt20">
      <van-field v-model="userInfo.issuedBy" label="签发机关"> </van-field>
      <van-field v-model="userInfo.validityPeriod" label="有效期限" />
    </div>
    <div class="mt40 rem36">
      <!-- <van-button
        style="font-size: .32rem !important"
        round
        block
        :type="canSave ? 'default' : 'info'"
        :disabled="canSave"
        @click="handleEdit"
      >
        编辑
      </van-button> -->
      <van-button
        style="margin-top: 0.2rem;font-size: .32rem !important"
        round
        block
        type="info"
        @click="handleSave"
      >
        保存
      </van-button>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Form, Field, Button, Toast } from 'vant'
import { confirmUpdateUserInfoApi } from '@/apis/userCenter/index'
import { Decrypt } from '@/utils/crypto'

Vue.use(Form)
  .use(Field)
  .use(Button)
export default {
  data() {
    return {
      userInfo: {
        name: '',
        idNo: '',
        gender: '',
        nation: '',
        birthDay: '',
        address: '',
        issuedBy: '',
        validityPeriod: '',
      },
    }
  },
  created() {
    this.userInfo = JSON.parse(this.$route.query.userInfo)
    this.userInfo.idNo = Decrypt(this.userInfo.idNo)
  },
  mounted() {},
  computed: {},
  methods: {
    handleEdit() {},
    // 保存身份证信息修改
    handleSave() {
      const query = { ...this.userInfo }
      const { reverImgId } = query
      query.reverseImgId = reverImgId
      query.frontImageTypeCode = 1
      query.reverseImageTypeCode = 2
      const loadingToast = Toast.loading({
        message: '修改中...',
        forbidClick: true,
      })
      confirmUpdateUserInfoApi(query).then(() => {
        loadingToast.clear()
        this.$toast({
          type: 'success',
          message: '修改成功',
          duration: 2000,
          onClose: () => {
            this.$router.push('/userCenter')
          },
        })
      })
    },
  },
}
</script>

<style scoped lang="less">
.van-cell {
  padding: 0;
  padding-bottom: 0.12rem;
  border-bottom: 1px solid #e7e7e7;
  margin-top: 0.32rem;
}
.check-info {
  margin-bottom: 0.4rem;
}
.check-userinfo {
  padding: 0.02rem 0.28rem 0.28rem 0.4rem;
  box-shadow: 0px 0px 0.3rem 0px rgba(0, 0, 0, 0.08);
  border-radius: 0.08rem;
  .check-color {
    /deep/ .van-field__control {
      color: #b9bcc2 !important;
    }
  }
}
</style>
