import request from '@/utils/request'
import { Encrypt } from '@/utils/crypto'

export function getUserInfo() {
  return request({
    method: 'post',
    url: '/cls/passport-personal/app/user/info/getUserFrontMsg',
  })
}
// 修改信息确认
export function confirmUpdateUserInfoApi(data) {
  const query = { ...data }
  query.idNo = Encrypt(query.idNo)
  return request({
    method: 'post',
    url: '/cls/passport-personal/app/common/ConfirmUpdateMsg',
    data: query,
  })
}
/* 修改手机号功能 */

// 修改注册手机号  获取验证码
export function getVerifyCodeApi(data) {
  const query = { ...data }
  query.mobile = Encrypt(query.mobile)
  return request({
    method: 'post',
    url: '/cls/passport-personal/app/common/sendCode',
    data: query,
  })
}
// 验证身份确认
export function checkOldPhoneApi(data) {
  const query = { ...data }
  query.mobile = Encrypt(query.mobile)
  return request({
    method: 'post',
    url: '/cls/passport-personal/app/updateMobile/checkCode',
    data: query,
  })
}

// 确认修改注册手机号
export function comfirmUpdateApi(data) {
  const query = { ...data }
  query.mobile = Encrypt(query.mobile)
  return request({
    method: 'post',
    url: '/cls/passport-personal/app/updateMobile/updateNewMobile',
    data: query,
  })
}

/* 修改银行卡号 */
// 获取验证码
export function getBankChangeVerifyApi(data) {
  const query = { ...data }
  query.idNo = Encrypt(query.idNo)
  query.cardNo = Encrypt(query.cardNo)
  query.phoneNo = Encrypt(query.phoneNo)
  return request({
    method: 'post',
    url: '/cls/passport-personal/app/user/sendVCodeByFourBasic',
    data: query,
  })
}
export function getBankCardNumberByOrc(data) {
  return request({
    method: 'post',
    url: '/cls/passport-personal/app/user/info/ocrBankCardNo',
    data,
  })
}

// 绑卡
export function bindBankCardApi(data) {
  const query = { ...data }
  query.idNo = Encrypt(query.idNo)
  query.cardNo = Encrypt(query.cardNo)
  query.phoneNo = Encrypt(query.phoneNo)
  return request({
    method: 'post',
    url: '/cls/passport-personal/app/user/bindBankCardByFourBasic',
    data: query,
  })
}

// 改卡
export function updateBankCardApi(data) {
  const query = { ...data }
  query.idNo = Encrypt(query.idNo)
  query.cardNo = Encrypt(query.cardNo)
  query.phoneNo = Encrypt(query.phoneNo)
  return request({
    method: 'post',
    url: '/cls/passport-personal/app/user/updateBankCardByFourBasic',
    data: query,
  })
}

/* 修改企业信息 */

// 获取用户所属企业信息
export function getCompanyListApi(data) {
  const headers = {
    dataType: 'text',
  }
  return request({
    method: 'post',
    url: '/cls/passport-personal/app/user/info/userCompanyList',
    data,
    headers,
  })
}
// 修改企业信息
export function updateCompanyInfoApi(data) {
  const query = { ...data }
  query.idNo = Encrypt(query.idNo)
  return request({
    method: 'post',
    url: '/cls/passport-personal/app/user/info/updateEnterpriseInfo',
    data: query,
  })
}
// 查询用户状态
export function getUserFrontBasicMsgApi(data) {
  return request({
    method: 'post',
    url: '/cls/passport-personal/app/user/info/getUserFrontBasicMsg',
    data,
  })
}
// 查询登录协议
export function getRegAgreementNumApi() {
  return request({
    method: 'post',
    url: '/cls/passport-personal/app/common/getRegAgreementNum',
  })
}
// 上传获取fileId
export function uploadFileNewApi(params, data) {
  return request({
    method: 'post',
    url: `/cls/passport-personal/app/common/uploadFileNew`,
    params,
    data,
  })
}
// 营业执照OCR（new）
export function parseLicenseInfoApi(data) {
  return request({
    method: 'post',
    url: `/cls/passport-personal/app/user/info/parseLicenseInfoByFieldId`,
    params: data,
  })
}
// 身份证OCR识别（new）
export function OcrIdCardNewApi(data) {
  return request({
    method: 'post',
    url: `/cls/passport-personal/app/common/OcrIdCardNew`,
    params: data,
  })
}

// 修改身份证 OCR识别
export function OcrIdCardUpdateApi(data) {
  return request({
    method: 'post',
    url: `/cls/passport-personal/app/common/updateMsgByOcrIdCardNew`,
    params: data,
  })
}

// 银行卡识别OCR(NEW)
export function ocrBankCardNoNewApi(data) {
  return request({
    method: 'post',
    url: `/cls/passport-personal/app/user/info/ocrBankCardNoNew`,
    data,
  })
}
